<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ editData ? 'Edit' : 'Add' }} Bank-Branch</h1>
                            <button 
                                @click="closeModal" 
                                type="button" 
                                class="btn-close" 
                                data-bs-dismiss="modal"
                                aria-label="Close">
                            </button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="modal-body">

                    <div class="pb-2">

                        <div class="row">

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Select contact profile</label>
                                <AsyncSelect
                                    placeholder="Select contact profile"
                                    v-model="contact"
                                    :api-service="fetchContactProfiles"
                                    :additional-query="{ type: 'dealer'}"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Full name</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model="formData.name"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter Full name"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Email</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model="formData.contact_emails"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter email"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Phone</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model="formData.contact_numbers"
                                        type="text"
                                        class="form-control"
                                        placeholder="Enter phone"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <label class="form-label">Address</label>
                                <div class="input-group">
                                    <textarea
                                        rows="2" name="Address"
                                        placeholder="Enter address"
                                        v-model.trim="formData.address"
                                        class="form-control"
                                    ></textarea>
                                </div>
                            </div>
                           
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Select BDO</label>
                                <AsyncSelect
                                    placeholder="Select BDO"
                                    v-model="selectedSR"
                                    :api-service="fetchAllSR"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Select area</label>
                                <AsyncSelect
                                    placeholder="Select area"
                                    v-model="area"
                                    :api-service="fetchAreaList"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Status</label>
                                <v-select
                                    placeholder="Select status"
                                    :options="noteStatus" v-model="formData.status"
                                    :reduce="name => name.value"
                                    label="name"
                                />
                            </div>

                            <div class="col-12 text-center">

                                <hr class="mt-2">

                                <button 
                                    :disabled="loading" @click="submit"
                                    class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
                                >
                                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    Submit
                                </button>

                                <button @click="closeModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import { inject, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import handleContact from '@/services/modules/contact';
import handleCrm from '@/services/modules/crm/crm'

const props = defineProps({
    editData: Object | null,
    onSubmit: Function,
    noteStatus: Array
})

const emit = defineEmits(['onSubmit']);

const { fetchContactProfiles } = handleContact();
const { fetchAreaList, fetchAllSR, storeDealer, updateDealer } = handleCrm();

const store = useStore();
const route = useRoute();

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false);
const additionalAreaOption = ref([]);
const contact = ref(null);
const selectedSR = ref(null);
const area = ref(null);

const formData = reactive({
    company_id: null,
    contact_profile_id: null,
    area_id: null,
    sale_representative_id: null,
    name: '',
    contact_emails: '',
    contact_numbers: '',
    address: '',
    status: 'active'
});

const closeModal = () => {
    store.state.isModalOpen = false;
}

const submit = async () => {

    if(selectedSR.value) formData.sale_representative_id = selectedSR.value.id
    if(contact.value) formData.contact_profile_id = contact.value.id
    if(area.value) formData.area_id = area.value.id
    formData.company_id = route.params.companyId;

    const id = props.editData ? props.editData.id : null;
    const srAction = id ? updateDealer : storeDealer;

    loading.value = true

    await srAction(formData, id).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        closeModal();
        emit('onSubmit');
        return showSuccess(res.message);
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const setEditData = (data) => {

    formData.contact_profile_id = data.contact_profile_id;
    formData.area_id = data.area_id;
    formData.sale_representative_id = data.sale_representative_id;
    formData.name = data.name;
    formData.contact_numbers = data.contact_numbers;
    formData.contact_emails = data.contact_emails;
    formData.address = data.address;
    formData.status = data.status

    contact.value = {
        id: data.contact_profile_id,
        name: data.contact_profile.full_name
    }

    if(data.area){
        area.value = data.area
    }

    if(data.sale_representative_id){
        selectedSR.value = {
            id: data.sale_representative_id,
            name: data.sr_name
        }
    }
    
}

onMounted(() => {
    if(props.editData){
        setEditData(props.editData)
    }
})

</script>


<style scoped>
.med-title {
    padding: 0.486rem 1.5rem;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>